import {
  USER
} from '../api'
import {
  METHOD,
  request
} from '@/utils/request'

// 调动列表
export async function transferList(params) {
  return request(`${USER}/transfer/applyTransfer/transferList`, METHOD.GET, params)
}

// 失效列表
export async function alreadyTransferList(params) {
  return request(`${USER}/transfer/applyTransfer/alreadyTransferList`, METHOD.GET, params)
}

// 调整入职时间
export async function entryDate(params) {
  return request(`${USER}/transfer/applyEntry/entryDate`, METHOD.POST, params)
}

// 办理调动
export async function addTransfer(params) {
  return request(`${USER}/transfer/applyTransfer/addTransfer`, METHOD.POST, params)
}

// 获取租户办公地点列表
export async function workAddress(params) {
  return request(`${USER}/workAddress/getList`, METHOD.GET, params)
}
// 获取租户合同公司列表
export async function contractCompany(params) {
  return request(`${USER}/contractCompany/getList`, METHOD.GET, params)
}

// 获取调动前数据
export async function getDetailsBefore(params) {
  return request(`${USER}/transfer/applyTransfer/getDetailsBefore`, METHOD.GET, params)
}

// 调动中导出
export async function transferListExport(params) {
  return request(`${USER + '/transfer/applyTransfer/transferListExport'}`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}

// 已失效导出
export async function alreadyTransferListExport(params) {
  return request(`${USER + '/transfer/applyTransfer/alreadyTransferListExport'}`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}

// 获取调动详情
export async function getTransferDetail(params) {
  return request(`${USER}/transfer/applyTransfer/getTransferDetail`, METHOD.GET, params)
}
